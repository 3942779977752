.flex-con{
    display: -moz-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
}
.flex-space-around{
    -moz-justify-content: space-around;
    -o-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
}
.flex-space-center{
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.flex-space-between{
    -moz-justify-content: space-between;
    -o-justify-content: space-between;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.flex-align-center{
    -moz-align-items: center;
    -o-align-items: center;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.flex-align-start{
    align-items:flex-start
}

.flex-wrap{
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

